@font-face {
  font-family: 'Now';
  src: url('../tools/now/Now-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Now';
  src: url('../tools/now/Now-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Now', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
