body {
  background-color: black;
  color: #fff;
  margin: 0px;
  width: 100%;
}
p {
  font-size: larger;
  text-align: justify;
}
li {
  font-size: larger;
}
.App {
  width: 95%;
  max-width: 1400px;
  height: fit-content;
  min-height: 100vh;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.logoFooter {
  height: 50px;
  margin-bottom: 20px;
}
footer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  width: 85%;
}
h1 {
  color: #8e06fd;
  text-align: center;
}
a {
  text-decoration: none;
  color: #8e06fd;
}
.header {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  height: fit-content;
}
.profilePicLinkDiv {
  width: 18%;
}
.profilePic {
  border-radius: 50%;
  width: 100%;
  height: auto;
  object-fit: cover;
}
.navContainer {
  width: 82%;
  display: flex;
  align-items: center;
}
.navList {
  width: 100%;
  display: flex;
  list-style-type: none;
  flex-direction: row;
  justify-content: space-around;
  font-size: clamp(16px, 1.8vw, 30px);
  font-weight: 700;
}
.navList a {
  color: #fff;
  text-decoration: underline;
}

.mainProjects {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 85%;
}

.homeProjects {
  width: 100%;
  display: grid;
  grid-template-columns: 48% 48%;
  column-gap: 4ch;
  row-gap: 5ch;
  align-items: center;
}

.projectSection {
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: #51414f;
  border-radius: 25px;
  color: white;
}
.projectSection h2 {
  width: 80%;
  text-align: left;
}
.projectSection p {
  width: 80%;

  text-align: justify;
}
.projectSection:hover {
  cursor: pointer;
  box-shadow: 7px 7px 7px #301934;
}

.galleryImg {
  height: 400px;
  max-width: 80%;
  min-width: 80%;
  object-fit: cover;
  object-position: top left;
  background-color: white;
  border-radius: 25px;
}

.mainProjectPage {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.linkProjectDiv {
  width: 80%;
}
.projectNameAndDescDiv {
  width: 80%;
}
.projectDetailsAndTechDiv {
  width: 80%;
  text-align: justify;
}
.projectStatusDiv {
  width: 80%;
}
.projectUrl {
  width: 100%;
}
.projectMini {
  height: 350px;
  width: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: 25px;
}
.usedTechList {
  list-style-type: none;
  display: grid;

  min-width: 80%;

  min-height: 100px;
  padding: 0px;
  display: grid;
  grid-template-columns: 20% 20% 20% 20%;
  column-gap: 5ch;
  row-gap: 5ch;
  align-items: center;
}
.techLogo {
  height: 100px;
}
.techItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  min-width: 200px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.backToProjectsLink {
  align-self: flex-start;
  width: 160px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}
.navigationHelp {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.siteVisit {
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: flex-end;
}
.siteVisitItem {
  margin-left: 10px;
}
.nonResponsive {
  display: none;
}
.jobsMain {
  display: flex;

  flex-direction: column;
  align-items: center;
  width: 100%;
}
.homeJobs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 84%;
}

.jobSection {
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #51414f;
  border-radius: 25px;
  color: white;
}
.firstJobDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.secondJobDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.jobImg {
  height: 150px;
  width: 50%;

  object-fit: contain;
  background-color: black;
  border-radius: 25px;
}
.jobDatesDiv {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.jobDatesDiv h3 {
  margin-right: 5px;
}
.jobMissionsDiv {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.jobMissionsList {
  list-style-type: disc;
}
.jobMission {
  width: 100%;
  text-align: justify;
  margin-bottom: 10px;
}
.jobToolsDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: flex-end;
}
.usedToolsList {
  padding: 0px;
  display: flex;
  width: 80%;
  max-width: 80%;
  justify-content: space-between;
  flex-wrap: wrap;
  list-style-type: none;
  text-align: center;
}
.toolsItem {
  width: 30%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.jobDetail {
  text-align: justify;
  width: 85%;
}
.joblink {
  justify-self: flex-end;
  align-self: flex-end;
  margin-right: 8%;
  line-height: 40px;
  background-color: white;
  border-radius: 20px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 20px;
}
.joblink:hover {
  box-shadow: 5px 5px 5px;
}
.schoolsMain {
  display: flex;

  flex-direction: column;
  align-items: center;
  width: 100%;
}
.homeSchools {
  padding-top: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 84%;
}

.schoolSection {
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #51414f;
  border-radius: 25px;
  color: white;
}
.firstSchoolDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.secondSchoolDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.schoolImg {
  height: 150px;
  width: 50%;

  object-fit: contain;
  background-color: black;
  border-radius: 25px;
}
.schoolDatesDiv {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.schoolDatesDiv h3 {
  margin-right: 5px;
}
.schoolMissionsDiv {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.SchoolDescription {
  width: 100%;
  text-align: justify;
}

.schoolLink {
  justify-self: flex-end;
  align-self: flex-end;
  margin-right: 8%;
  text-align: center;
  line-height: 40px;
  background-color: white;
  border-radius: 20px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 20px;
}
.schoolLink:hover {
  box-shadow: 5px 5px 5px;
}
.contactMain {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.contactFormSection {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  background-color: #51414f;
  border-radius: 25px;

  padding-top: 25px;
  padding-bottom: 25px;
}
.contactForm {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  width: 80%;
  height: fit-content;
}
.formLabel {
  width: 100%;
  font-weight: 700;
  font-size: 1.5em;
}
.input {
  height: 40px;
  width: 90%;
  padding-left: 5%;
  padding-right: 5%;
  border-style: none;
  border-radius: 25px;
}
.inputMessage {
  width: 90%;
  display: inline;
  resize: none;
  overflow-y: scroll;
  min-height: 200px;
  padding: 5%;
  font-weight: 400;
  text-align: left;
  vertical-align: top;
}
.submitBtn {
  border: none;
  background-color: #301934;
  color: #fff;
  height: fit-content;
  padding: 25px;
  align-content: center;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 25px;
  border-radius: 25px;
  font-weight: 700;
  font-size: 1.5em;
}
.submitBtn:hover {
  cursor: pointer;
  background-color: white;
  color: #51414f;
}
.messageSent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 25px;
  border-radius: 25px;
  width: 80%;
  font-size: 1.5em;
}
.linkBackHome {
  border: none;
  background-color: #301934;
  color: #fff;
  height: fit-content;
  padding: 25px;
  align-content: center;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 25px;
  border-radius: 25px;
  font-weight: 700;
  font-size: 1.5em;
}
.linkBackHome:hover {
  cursor: pointer;
  background-color: white;
  color: #51414f;
}

.home {
  display: flex;
  justify-content: space-around;
  width: 80%;
  flex-direction: column;
  align-items: center;
}

.logoImg {
  height: 120px;
}
