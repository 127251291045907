@media screen and (max-width: 720px) {
  body {
    width: 100%;
  }
  .App {
    width: 100%;
  }
  h1 {
    font-size: 22px;
  }
  h2 {
    font-size: 18px;
  }
  h3 {
    font-size: 16px;
  }
  p {
    font-size: 14px;
  }

  .header {
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }
  .profilePicLinkDiv {
    width: 50%;
  }
  .profilePic {
    border-radius: 50%;
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  .navContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 80%;
    max-width: 80%;
  }
  .navList {
    flex-wrap: wrap;
    justify-content: space-around;
    font-size: clamp(16px, 1.8vw, 30px);
    font-weight: 700;
    text-align: center;
    padding-left: 0px;
  }
  .navListItem {
    width: 50%;
    font-size: 1.1em;
    line-height: 40px;
  }
  .projectSection {
    height: 400px;
  }
  .projectSection h2 {
    text-align: center;
  }
  .galleryImg {
    min-width: 100%;
    object-fit: cover;
    object-position: top left;
    background-color: white;
    border-radius: 0px 0px 25px 25px;
  }
  .projectSection p {
    display: none;
  }
  .backToProjectsLink {
    align-self: flex-start;
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    font-size: 14px;
  }
  .siteVisit {
    width: 50%;
    font-size: 14px;
  }
  .projectName {
    font-size: 22px;
  }
  .jobImg {
    height: 150px;
    width: 80%;
  }
  .trainingTitle {
    width: 90%;
    text-align: center;
  }
  .socialSection {
    display: flex;
    flex-direction: column;
  }

  .jobMissionsList {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    padding: 0px;
  }
  .jobMissionsList li {
    font-size: 14px;
  }
  .linkBackHome {
    padding: 15px;
    font-weight: 400;
    font-size: 1em;
  }
}
