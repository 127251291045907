@media screen and (max-width: 989px) {
  .app {
    display: flex;
    flex-direction: column;
  }
  .header {
    width: 100%;
  }
  .profilePicLinkDiv {
    width: 15%;
  }
  .nonResponsive {
    display: flex;
    color: red;
  }
  .mainProjects {
    width: 80%;
  }
  .homeProjects {
    display: flex;
    flex-direction: column;
  }
  .usedTechList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .homeJobs {
    flex-direction: column;
  }
  .jobSection {
    width: 100%;
    margin-bottom: 25px;
    margin-top: 25px;
  }
  .joblink {
    align-self: center;
    justify-self: center;
    width: 60%;
    text-align: center;
    margin-right: 0px;
    line-height: 50px;
  }
  .homeSchools {
    flex-direction: column;
  }
  .schoolSection {
    width: 100%;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .schoolLink {
    align-self: center;
    justify-self: center;
    margin-right: 0px;
    width: 60%;
    line-height: 50px;
  }
  .contactFormSection {
    width: 85%;
  }
}
